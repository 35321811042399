html {
  scroll-behavior: smooth;
}

/* Scroll Margin Adjustment */
.section {
  scroll-margin-top: 60px; /* Adjust this value to match the height of your toolbar */
}

html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  width: 100%;
}

body {
  background-color: #f5f0e5; /* Replace with the color you want */
  padding-bottom: 5px;
}

/* Hero section with background video minus the toolbar height */
.hero-section {
  position: relative;
  height: calc(100vh);
  width: 100%;
  overflow: hidden;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.hero-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  max-width: 80%;
  color: #ffffff;
}

.small-text {
  font-size: 30px;
  font-weight: 500;
}

.main-text {
  font-size: 120px;
  font-weight: bold;
  margin: 30px 0;
}

.sub-text {
  font-size: 36px;
  margin-bottom: 60px;
}

.services-button {
  display: inline-block;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: bold;
  background-color: #ffffff;
  color: #b87330;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.services-button:hover {
  background-color: #241504;
}

#services h2 {
  font-size: 36px;
  margin-bottom: 20px;
}

#services p {
  font-size: 30px;
  max-width: 800px;
  margin: 0 auto;
}

/* Project section */
.project-section {
  padding: 50px calc(10vh) 0px calc(10vh);
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

/* Container for content */
.project-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* Text content */
.text-content {
  flex: 1;
  padding-right: calc(10vh); /* Add some spacing between text and image */
  text-align: left; /* Align text to the left */
}

.text-content h2 {
  color: #b87330; /* Specify h2 color */
  font-size: 36px;
  margin-bottom: 40px;
  text-align: left;
}

.text-content p {
  color: #623e2a; /* Specify p text color */
  /* Justify text */
  text-align: justify;
  font-size: 24px;
  font-weight: 450;
}

/* Image content */
.image-content {
  flex: 1;
  text-align: right; /* Align image to the right */
}

.image-content img {
  max-width: 70%; /* Ensure image is responsive */
  height: auto;
  border-radius: 20px; /* Optional: Add rounded corners */
}

/* Make the layout responsive */
@media (max-width: 1368px) {
  .project-content {
    flex-direction: column; /* Stack the content vertically on small screens */
    align-items: center;
  }

  .project-content {
    max-width: 100%; /* Full width on small screens */
  }

  .text-content {
    padding-right: 0; /* Remove padding on small screens */
  }

  .image-content img {
    margin-top: 20px; /* Add space between text and image */
  }

  .text-content h2 {
    font-size: 30px; /* Adjust font size for smaller screens */
    text-align: center;
  }
}

/* General styles for the section ----------------------------------------------------*/
.about-section {
  padding: 40px 20px;
  background-color: #f5f0e5; /* Soft beige color for the background */
  text-align: center;
  margin-bottom: 20px;
}

.about-section h2 {
  font-size: 36px;
  margin-bottom: 40px;
  color: #b87330; /* Dark brown text color */
}

/* Flexbox layout for the three boxes */
.about-content {
  display: flex;
  justify-content: space-between;
  align-items: stretch; /* Stretch the boxes to have equal height */
  gap: 30px; /* Add space between the boxes */
}

.about-box {
  flex: 1; /* Equal width for each box */
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  text-align: left; /* Left-align the text inside */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
}

/* Ensure the image is centered and doesn't stretch the box size */
.about-box img {
  width: 100%;
  height: 400px;
  object-fit: cover; /* Remplit la box sans déformer l'image */
  object-position: center; /* Centre l'image */
  border-radius: 8px;
  margin-bottom: 20px;
  display: block;
}


/* Styling for the heading in the boxes */
.about-box h3 {
  font-size: 20px;
  color: #5a3921;
  margin-bottom: 10px;
  display: inline-flex; /* Flex layout for the h3 and the span */
  align-items: center;
}

/* Style for the "Co-Fondateur" span to appear smaller and to the right */
.about-box h3 .cofounder {
  font-size: 20px; /* Smaller font size */
  color: #5a3921; /* Same dark brown */
  margin-left: 10px; /* Add space between name and cofounder */
  font-style: italic;
  font-weight: normal; /* Remove bold style */
}

/* Styling for the paragraph in the boxes */
.about-box p {
  font-size: 16px;
  color: #5a3921; /* Same dark brown for the paragraph */
  line-height: 1.5;
  flex-grow: 1; /* Ensure the paragraph takes up remaining space */
}

/* Make the layout responsive */
@media (max-width: 1368px) {
  .about-content {
    flex-direction: column; /* Stack the boxes vertically on small screens */
    align-items: center;
  }

  .about-box {
    max-width: 100%; /* Full width on small screens */
  }
}


/* Value section layout -------------------------------------------------------*/
.value-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background-color: #f5f0e5; /* Background color like the rest of the site */
  margin-bottom: 30px;
}

.value-text {
  width: 60%; /* Left section width */
}

.value-text h2 {
  font-size: 36px;
  color: #b87330;
  margin-bottom: 20px;
}

.value-text p {
  font-size: 24px;
  font-weight: 400;
  color: #5a3921;
}

/* Right side image */
.value-image {
  width: 30%; /* Adjust the width to fit nicely with the text */
  position: relative;
}

.value-image img {
  width: 100%;
  height: 70vh;
  object-fit: cover;
  border-radius: 8px; /* Optional for rounded corners */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 10); /* Soft shadow around the image */
}

.image-caption {
  width: 100%;
  text-align: center;
  font-size: 16px;
  color: #5a3921;
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #5a3921;
  border-radius: 5px;
  display: inline-block;
  font-style: italic;
  box-sizing: border-box;
}

/* Responsive design for smaller screens */
@media (max-width: 1368px) {
  .value-section {
    flex-direction: column;
    align-items: center;
  }

  .value-text, .value-image {
    width: 85%;
    text-align: center;
  }

  .image-caption {
    width: auto;
  }
}

/* Services section styling */
.services-section {
  padding: 30px 20px 20px;
  text-align: center;
  background-color: #f5f0e5; /* Adjust the background color */
  margin-bottom: 40px;
}

.services-section h2 {
  font-size: 36px;
  color: #b87330;
  padding-bottom: 40px;
}

.services-container {
  display: flex;
  justify-content: space-between;
  gap: 15px; /* Adds spacing between the columns */
  flex-wrap: wrap; /* Ensures responsiveness on smaller screens */
}

.service-box {
  flex: 1;
  max-width: 30%; /* Ensure the three boxes have similar widths */
  text-align: center;
  padding: 15px;
  border: 1px solid #5a3921;
  border-radius: 8px;
  box-sizing: border-box;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-box img {
  width: 100%;
  height: 50vh;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 20px;
}

.service-box h3 {
  font-size: 20px;
  color: #5a3921;
  margin-bottom: 15px;
}

.service-box p {
  font-size: 18px !important;
  color: #5a3921;
}

.service-box:hover {
  transform: translateY(-10px); /* Slight upward movement on hover */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Add a shadow effect */
}

@media (max-width: 1368px) {
  .about-content {
    flex-direction: column; /* Stack the boxes vertically on small screens */
    align-items: center;
  }

  .about-box {
    max-width: 100%; /* Full width on small screens */
  }
}

@media (max-width: 1368px) {
  .services-container {
    flex-direction: column; /* Stack the boxes vertically on small screens */
    align-items: center;
  }

  .service-box {
    max-width: 100%; /* Full width for smaller screens */
  }
}

/* Trust section with background ------------------------------------*/
.trust-section {
  position: relative;
  height: 100vh; /* Full viewport height */
  background: url('../public/confiance.webp') no-repeat center center/cover;
  color: #efebe5;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Prevent overflow */
}

.background-overlay {
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.trust-section h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  z-index: 2;
  position: relative;
}

@media (max-width: 1368px) {
  .trust-section h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
}

/* Trust section styling */
.trust-section {
  position: relative;
  min-height: 100vh;
  background: url('../public/confiance.webp') no-repeat center center/cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.trust-section h2 {
  color: #fff;
  font-size: 2.5rem;
  margin-bottom: 30px;
  z-index: 2;
  text-align: center;
}

.testimonial-carousel {
  position: relative;
  width: 80%;
  max-width: 800px;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 15px;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.testimonial-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
}

.testimonial-image-wrapper {
  width: 400px;
  height: 400px;
  position: relative;
  margin-bottom: 20px;
}

.testimonial-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.testimonial-caption {
  width: 100%;
  text-align: center;
  font-size: 16px;
  color: #5a3921;
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #5a3921;
  border-radius: 5px;
  display: inline-block;
  font-style: italic;
  box-sizing: border-box;
  background-color: white;
}

.carousel-button {
  background: transparent;
  border: none;
  color: #5c3e30;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
  transition: color 0.3s ease;
}

.carousel-button:hover {
  color: #b87330;
}

@media (max-width: 768px) {
  .trust-section h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }

  .testimonial-carousel {
    width: 95%;
    padding: 15px;
    background-color: transparent;
    box-shadow: none;
  }

  .testimonial-container {
    margin: 0 10px;
  }

  .testimonial-image-wrapper {
    width: 100%;
    height: auto;
    aspect-ratio: 1;
    margin-bottom: 10px;
  }

  .testimonial-caption {
    font-size: 12px;
    padding: 8px;
    background-color: rgba(255, 255, 255, 0.9);
    color: #5a3921;
    border-color: white;
  }

  .carousel-button {
    font-size: 20px;
    color: white;
  }

  .carousel-button:hover {
    color: #ffffff80;
  }
}

@media (max-width: 480px) {
  .trust-section h2 {
    font-size: 1.5rem;
  }

  .testimonial-carousel {
    padding: 10px;
  }

  .testimonial-caption {
    font-size: 11px;
    padding: 6px;
  }

  .carousel-button {
    font-size: 18px;
  }
}


/* Contact section styling */
.contact-section {
  text-align: center;
  background-color: #f5f0e5; /* Light beige background */
}

.contact-section h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #b87330; /* Adjust according to the theme */
}

.contact-section h3 {
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: #5a3921; /* Adjust according to the theme */
  font-weight: 500;
}


.contact-info {
  font-weight: 600;
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Ensure responsiveness on smaller screens */
}

.contact-box {
  background-color: #f8f4ef; /* Light beige background */
  padding: 40px;
  border: 1px solid #cfc2b2;
  width: 80%;
  max-width: 1000px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  position: relative;
}

.contact-left, .contact-right {
  width: 45%;
}

.contact-left {
  text-align: center;
}

.contact-right {
  color: #5a3921;
  font-weight: 600;
  text-align: center;
}

.vertical-line {
  width: 1px;
  height: 80%;
  background-color: #cfc2b2;
  margin: 0 20px;
}

.contact-item {
  margin-bottom: 20px;
}

.contact-item .text {
  font-size: 1rem;
  color: #5c3e30;
}

.contact-item .text a {
  text-decoration: none;
  color: #5c3e30;
  font-weight: normal; /* Ensure email text is not bold */
}

.contact-item .text a:hover {
  text-decoration: underline;
}

/* Social media icons */
.social-media-icons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.social-media-icons a {
  margin: 0 10px;
  color: #5c3e30;
  font-size: 1.5rem;
  transition: color 0.3s ease;
}

.social-media-icons a:hover {
  color: #9c7a64;
}

/* Styles for mobile screens */
@media (max-width: 1368px) {
  .contact-box {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px; /* Adjust padding for better fit */
  }

  .contact-left, .contact-right {
    width: 100%;
    text-align: center;
  }

  .vertical-line {
    display: none;
  }

  .contact-item {
    margin-bottom: 10px;
  }

  .social-media-icons {
    margin-top: 10px;
  }
}




/* Mobile optimizations */
@media (max-width: 1368px) {
  .hero-section {
    height: 85vh;
    overflow: hidden;
  }

  .hero-content {
    font-size: 1.5rem;
  }

  .small-text {
    font-size: 24px;
  }

  .main-text {
    font-size: 80px;
  }

  .sub-text {
    font-size: 28px;
  }

  .services-button {
    font-size: 16px;
    padding: 10px 20px;
  }

  .project-section, .value-section, .services-section, .prestations-section, .trust-section, .contact-section {
    padding: 20px;
  }

  .project-content, .about-content, .value-text, .services-container, .prestations-content, .testimonials {
    flex-direction: column;
    align-items: center;
  }

  .text-content h2, .value-text h2, .services-section h2, .prestations-box h2, .trust-section h2, .contact-section h2 {
    font-size: 2rem;
  }

  .text-content p, .value-text p, .services-box p, .prestations-box p, .testimonial p {
    font-size: 1rem;
  }

  .image-content img, .value-image img, .service-box img, .prestations-box img, .testimonial img {
    max-width: 100%;
    height: auto;
    margin: 10px 0;
  }

  .contact-box {
    width: 100%;
    margin-bottom: 20px;
  }

  .contact-box h3 {
    font-size: 1.2rem;
  }

  .contact-box p {
    font-size: 0.9rem;
  }

  .tag-button {
    font-size: 1rem;
    padding: 8px 16px;
  }
}
