/* Global Toolbar Styles */
.toolbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0);
  transition: background-color 0.3s ease;
}

.toolbar.scrolled {
  background-color: rgba(255, 255, 255, 1);
}

.toolbar-logo img {
  height: 40px;
  width: auto;
}

.toolbar-nav {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.toolbar-nav ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.toolbar-nav ul li {
  margin: 0 15px;
}

.toolbar-nav ul li a {
  text-decoration: none;
  color: #333;
  font-weight: 500;
  transition: color 0.2s ease-in-out;
}

.toolbar-nav ul li a.active,
.toolbar-nav ul li a:hover {
  color: #b87330; /* Highlight Color */
}

/* Hamburger Menu (Hidden on Large Screens) */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  background: white;
  border: none;
  padding: 10px; /* Add padding for better spacing */
  margin: 0;
}

.hamburger .line {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 4px auto;
  transition: all 0.3s ease;
}

/* Mobile Menu Styles */
@media (max-width: 768px) {
  .hamburger {
    display: flex;
  }

  .toolbar-nav {
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    background: white;
    padding: 20px 0;
    flex-direction: column;
    align-items: center;
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;
    display: none; /* Hide the nav by default */
  }

  .toolbar-nav.active {
    display: flex; /* Show the nav when active */
    transform: translateY(0);
  }

  .toolbar-nav ul {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
  }

  .toolbar-nav ul li {
    margin: 10px 0;
  }
}
